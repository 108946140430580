import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentSolution: 'MatrixGen',
  currentTab: 'Docs',
  currentTeam: { name: 'General' },
  currentModel: null,
  currentAside: 'Multi-File',
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setCurrentSolution(state, action) {
      state.currentSolution = action.payload;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setCurrentTeam(state, action) {
      state.currentTeam = action.payload;
    },
    setCurrentModel(state, action) {
      state.currentModel = action.payload;
    },
    setCurrentAside(state, action) {
      state.currentAside = action.payload;
    },
    toggleRefreshAside(state) {
      state.refreshAside = !state.refreshAside;
    },
  },
});

export const {
  setCurrentSolution,
  setCurrentTab,
  setCurrentTeam,
  setCurrentModel,
  setCurrentAside,
  toggleRefreshAside,
} = uiSlice.actions;

export default uiSlice.reducer;
