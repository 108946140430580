import React from 'react';
import AxiosInstance from './AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { ArrowLongRightIcon, ArrowLongLeftIcon } from '@heroicons/react/20/solid';

// Updated handleLogout function to accept navigate as an argument
function handleLogout(navigate) {
  AxiosInstance.post('/api/auth/logout/')
    .then(response => {
      // Handle success (e.g., redirect to login page)
      localStorage.removeItem("Token");
      // Navigate Docs
      navigate('/');
    })
    .catch(error => {
      // Handle error
      console.error('There was an error logging out!', error);
    });
}

export function RightLogoutButton({ extra_class='' }) {
  const navigate = useNavigate();

  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        handleLogout(navigate);
      }}
      className={`${extra_class} group inline-flex items-center border-t-2 border-transparent lg:px-6 lg:py-3 sm:px-2 sm:py-1 text-sm font-medium text-accent hover:text-accent-hover`}
    >
      Logout
      <ArrowLongRightIcon aria-hidden="true"
        className="ml-3 h-5 w-5 text-accent group-hover:text-accent-hover" />
    </a>
  );
}

export function LeftLogoutButton({ extra_class='' }) {
  const navigate = useNavigate();

  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        handleLogout(navigate);
      }}
      className={`${extra_class} group inline-flex items-center border-t-2 border-transparent text-sm font-medium text-accent hover:text-accent-hover`}
    >
      <ArrowLongLeftIcon aria-hidden="true"
                         className="mr-3 h-5 w-5 text-accent group-hover:text-accent-hover"/>
      Logout
    </a>
  );
}
