// useInViewAnimation.js
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';

export const useInViewAnimation = (ref, threshold=0.5, rootMargin='0px 0px -25% 0px') => {
  const controls = useAnimation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            controls.start('visible');
            observer.unobserve(entry.target); // Stop observing after animation starts
          }
        });
      },
      {
        threshold: threshold, // Trigger when threshold% of the component is visible
        rootMargin: rootMargin, // Adjust viewport margin
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [controls, ref]);

  return controls;
};
