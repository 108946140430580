// MatrixGen component
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { setCurrentSolution } from '../../../../utils/redux/uiSlice';

import MainContentButton from "./MainContentButton";

import {ListBulletIcon, CircleStackIcon, ShieldExclamationIcon} from "@heroicons/react/24/outline";

const solutionsArray = [
  { name: 'MatrixGen', visibleName: 'MatrixGen™', icon: ListBulletIcon, current: true },
  { name: 'RAG', visibleName: 'RAG', icon: CircleStackIcon, current: false },
  { name: 'HazardLogGen', visibleName: 'HazardLogGen™', icon: ShieldExclamationIcon, current: false },
  // Add more solutions here if needed
];

export default function SolutionsTest({ setSidebarOpen }) {
  const dispatch = useDispatch();
  const { currentTeam } = useSelector((state) => state.ui);

  const [solutionsList, setSolutionsList] = useState(solutionsArray);

  const handleMainContentChange = (item) => {
    console.log(item.name)
    dispatch(setCurrentSolution(item.name));
    setSolutionsList(solutionsList.map(solItem =>
      solItem.name === item.name
        ? { ...solItem, current: true }
        : { ...solItem, current: false }
    ));
    setSidebarOpen(false);
  };

  return (
    <ul role="list" className="-mx-2 mt-2 space-y-1">
      {solutionsList.map((item) => (
        <li key={item.name}>
          <MainContentButton item={item} onClick={() => handleMainContentChange(item)} />
        </li>
      ))}
    </ul>
  );
}