// services/modelRetrievalService.js
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AxiosInstance from "../components/AxiosInstance";
import { setCurrentModel } from "../utils/redux/uiSlice";

export function useModelRetrievalService() {
  const dispatch = useDispatch();
  const { currentSolution, currentModel, currentTeam } = useSelector((state) => state.ui);

  const [modelsList, setModelsList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentSolution) return;
      try {
        const response = await AxiosInstance.get(`/${currentSolution.toLowerCase()}_backend/models/`, {
          params: { team_name: currentTeam.name }
        });
        const fetchedModels = response.data;

        // Sort models alphabetically by model.name
        const sortedModels = fetchedModels.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive comparison
          const nameB = b.name.toUpperCase(); // Convert to uppercase for case-insensitive comparison

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names are equal
        });

        setModelsList(sortedModels);
      } catch (error) {
        console.error('Error fetching models data:', error);
      }
    };

    fetchData();
  }, [currentSolution, currentTeam.name]); // Added currentTeam.name to dependencies if it can change

  useEffect(() => {
    if (modelsList.length > 0 && !currentModel) {
      dispatch(setCurrentModel(modelsList[0]));
    }
  }, [modelsList, dispatch]);

  return modelsList;
}
