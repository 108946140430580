// multiFileSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { setCurrentModel } from '../../../../../utils/redux/uiSlice'
import {useSelector} from "react-redux";

const initialState = {
  // Steps Functionality
  currentStep: 0, // Initial step
  steps: [
    { id: '01', name: 'Upload Files', status: 'current' },
    { id: '02', name: 'Choose Classifications', status: 'upcoming' },
    { id: '03', name: 'Start Query', status: 'upcoming' },
  ],

  // Submission State
  isSubmitting: false,

  // User Inputs Store
  queryName: null,
  notificationPref: {
    email: false,
    sms: false,
  },
  files: [],

};

const multiFileSlice = createSlice({
  name: 'multiFileSlice',
  initialState,

  reducers: {

    // Step Navigation Reducers
    setStep: (state, action) => {
      state.currentStep = action.payload;
      state.steps.forEach((step, index) => {
        if (index < action.payload) {
          step.status = 'complete';
        } else if (index === action.payload) {
          step.status = 'current';
        } else {
          step.status = 'upcoming';
        }
      });
    },

    // Submission State Reducers
    setSubmitting: (state) => {
      state.isSubmitting = true;
    },
    setNotSubmitting: (state) => {
      state.isSubmitting = false;
    },

    // User Input Reducers
    setQueryName(state, action) {
      state.queryName = action.payload
    },
    setNotificationPref: (state, action) => {
      const preference = action.payload; // "email" or "sms"
      state.notificationPref[preference] = !state.notificationPref[preference];
    },

    // File Management Reducers
    addFile: (state, action) => {
      // Adds a new file to the files array
      state.files.push(action.payload);
    },
    removeFile: (state, action) => {
      // Removes a file from the files array based on its id
      state.files = state.files.filter(file => file.id !== action.payload);
    },

    // Reset the entire slice to initial state
    resetMultiFileSlice: () => initialState,
  },
});

// Create a thunk to reset multiple slices
// Create a thunk to reset multiple slices
export const resetMultiFileForm = () => (dispatch, getState) => {
  // Reset the multiFileSlice
  dispatch(resetMultiFileSlice());

  // Get the currentModel from the state
  const { currentModel } = getState().ui;

  // Dispatch to reset the current model
  dispatch(setCurrentModel({
    ...currentModel,
    selectedClassifications: [],         // Reset selected classifications
    toggleNoClassificationsEnabled: false, // Reset toggleNoClassificationsEnabled
    toggleAllEnabled: false
  }));
};

export const {
  setStep,
  setQueryName,
  setNotificationPref,
  addFile,
  removeFile,
  setSubmitting,
  setNotSubmitting,
  resetMultiFileSlice,
} = multiFileSlice.actions;

export default multiFileSlice.reducer;
