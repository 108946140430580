//BentoOld.js
import History from '../../../../assets/Home/history section.png'
import Performance from '../../../../assets/Home/performance.png'
import Solutions from '../../../../assets/Home/solutions.png'
import Security from '../../../../assets/Home/security.png'

export default function Bento() {
return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-center text-base/7 font-semibold text-gradient-primary">Integrate faster</h2>
        <p className="mx-auto mt-2 max-w-lg text-pretty text-center text-4xl font-semibold tracking-tight text-custom-white sm:text-5xl">
          Everything you need to deploy your solutions.
        </p>
        <div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px rounded-lg bg-gray-900 lg:rounded-l-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-custom-white max-lg:text-center">
                  Query History
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-secondary max-lg:text-center">
                  Access a detailed record of past queries and results for improved insights.
                </p>
              </div>
              <div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                <div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-3xl ring-1 ring-white/15 shadow-2xl">
                  <img
                    className="object-cover object-top"
                    src={History}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
          </div>
          <div className="relative max-lg:row-start-1">
            <div className="absolute inset-px rounded-lg bg-gray-900 max-lg:rounded-t-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-custom-white max-lg:text-center">
                  Performance
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-secondary max-lg:text-center">
                  Experience rapid processing, designed to handle large-scale tasks with maximum efficiency.
                </p>
              </div>
              <div className="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                <img
                  className="w-full max-lg:max-w-xs"
                  src={Performance}
                  alt=""
                />
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"></div>
          </div>
          <div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
            <div className="absolute inset-px rounded-lg bg-gray-900"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
              <div className="px-8 pt-8 sm:px-10 sm:pt-10">
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-custom-white max-lg:text-center">Security</p>
                <p className="mt-2 max-w-lg text-sm/6 text-secondary max-lg:text-center">
                  Safeguard your data with robust encryption and advanced security protocols.
                </p>
              </div>
              <div className="flex flex-1 items-center [container-type:inline-size] max-lg:py-6 lg:pb-2">
                <img
                  className="h-[min(152px,40cqw)] object-cover object-center mx-auto"
                  src={Security}
                  alt=""
                />
              </div>
            </div>
            <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"></div>
          </div>
          <div className="relative lg:row-span-2">
            <div className="absolute inset-px rounded-lg bg-gray-900 lg:rounded-r-[2rem]"></div>
            <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-r-[calc(2rem+1px)]">
              <div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                <p className="mt-2 text-lg/7 font-medium tracking-tight text-custom-white max-lg:text-center">
                  Solutions
                </p>
                <p className="mt-2 max-w-lg text-sm/6 text-secondary max-lg:text-center">
                  Integrate any AI solution, offering flexibility to adapt and scale with your specific business needs.
                </p>
              </div>
              <div className="relative min-h-[30rem] w-full grow">
                <div
                  className="absolute inset-x-10 bottom-0 top-10 overflow-hidden">
                  <img
                    className="object-cover object-top mx-auto rounded-t-3xl"
                    src={Solutions}
                    alt=""
                  />
                </div>
                {/*<div className="absolute bottom-0 left-10 right-0 top-10 overflow-hidden rounded-tl-xl bg-gray-900 shadow-2xl">*/}
                {/*  <div className="flex bg-gray-800/40 ring-1 ring-white/5">*/}
                {/*    <div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">*/}
                {/*      <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">*/}
                {/*        NotificationSetting.jsx*/}
                {/*      </div>*/}
                {/*      <div className="border-r border-gray-600/10 px-4 py-2">App.jsx</div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className="px-6 pb-14 pt-6">/!* Your code example *!/</div>*/}
                {/*</div>*/}
              </div>
            </div>
            <div
              className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-r-[2rem]"></div>
          </div>
        </div>
      </div>
    </div>
)
}
