// singleQueryHistoryRetrievalService.js
import AxiosInstance from "../components/AxiosInstance";

const fetchSingleQueryHistory = async (offset = 0, limit = 5) => {
    try {
        // Fetch paginated query history from the backend
        const response = await AxiosInstance.get('matrixgen_backend/single-query-history-api/paginate', {
            params: {
                offset: offset,
                limit: limit
            }
        });

        if (response.status !== 200) {
            throw new Error('Failed to fetch query history');
        }

        const data = response.data;
        return data; // Return the fetched queries
    } catch (error) {
        console.error('Error fetching query history:', error);
        throw error;
    }
};

const fetchSingleMostRecentQuery = async () => {
    try {
        // Fetch the most recent query from the backend
        const response = await AxiosInstance.get('matrixgen_backend/single-query-history-api/most-recent');

        if (response.status !== 200) {
            throw new Error('Failed to fetch the most recent query');
        }

        const data = response.data;
        return data; // Return the most recent query
    } catch (error) {
        console.error('Error fetching the most recent query:', error);
        throw error;
    }
};

export { fetchSingleQueryHistory, fetchSingleMostRecentQuery };