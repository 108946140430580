import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTab, setCurrentAside, setCurrentModel } from '../../../../utils/redux/uiSlice';

import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import ModelMenuNew from './ModelMenu';

export default function MainHeader({ tabs }) {
  const dispatch = useDispatch();
  const { currentTab, currentSolution, currentModel } = useSelector((state) => state.ui);

  const handleTabClick = (tabName) => {
    dispatch(setCurrentTab(tabName));

    if (tabName !== 'Docs') {
      dispatch(setCurrentAside(tabName))
    }

    // For when the noClassificationToggle is on and user switches from Multi-File to Single
    if (tabName === 'Single') {
      dispatch(setCurrentModel({
        ...currentModel,
        toggleNoClassificationsEnabled: false // Disable 'No Classifications'
      }))
    }
  };

  // Filter only the visible tabs
  const visibleTabs = tabs.filter(tab => tab.visible);

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-md">
      <div className="mx-auto">

        {/* For Mobile */}
        <div className="sm:hidden w-full">
          <div className="flex items-center justify-center space-x-4 mb-4">
            <Listbox value={currentTab} onChange={handleTabClick}>
              <Listbox.Label className="text-sm font-medium leading-6 text-custom-white">Select a tab:</Listbox.Label>
              <div className="relative w-48">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-md bg-gray-900 py-1.5 pl-3 pr-10 text-left text-secondary shadow-sm ring-1 ring-inset ring-gray-600 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                  <span className="block truncate">{currentTab || 'Select a tab'}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400"/>
                  </span>
                </Listbox.Button>
                <Listbox.Options
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {visibleTabs.map((tab) => (
                    <Listbox.Option
                      key={tab.name}
                      value={tab.name}
                      className={({active, selected}) =>
                        `relative cursor-default select-none py-2 pl-8 pr-4 ${
                          active ? 'bg-gray-800 text-white' : 'text-custom-white'
                        }`
                      }
                    >
                      {({selected}) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                            {tab.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-accent">
                              <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div className="w-full border-t border-gray-700 my-4"></div> {/* Divider */}
        </div>

        {/* For Desktop */}
        <div className="hidden sm:flex sm:items-center">
          <nav className="flex items-center justify-between w-full border-b border-gray-seperator-main py-md relative">

            {/* Left Side - Empty or Future Use */}
            <div className="flex-1">
              {/*<ModelMenuNew />*/}
            </div>

            {/* Center - Tabs */}
            <ul
              role="list"
              className="flex gap-x-8 text-sm font-semibold leading-7 text-tertiary mx-auto"
            >
              {visibleTabs.map((tab) => (
                <li key={tab.name}>
                  <button
                    onClick={() => handleTabClick(tab.name)}
                    className={`${
                      tab.name === currentTab ? 'text-primary-one hover:text-primary-one-hover' : 'text-secondary hover:text-secondary-hover'
                    }`}
                  >
                    {tab.name}
                  </button>
                </li>
              ))}
            </ul>

            {/* Right Side - Model Menu */}
            <div className="flex-1 flex justify-end">
              {/*<ModelMenuNew />*/}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
