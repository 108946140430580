// AsideButton component
import React from 'react';

const AsideButton = ({ item, onClick }) => {
  const buttonClasses = item.current
    ? 'bg-transparent hover:bg-gray-800 hover text-accent'
    : 'text-secondary hover:bg-gray-800 hover:text-secondary-hover';

  const iconClasses = item.current
    ? 'border-black text-black'
    : 'border-black bg-secondary text-black group-hover:bg-secondary-hover text-black';

  return (
    <button
      type="button"
      onClick={() => onClick(item)}
      className={`group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 ${buttonClasses}`}
    >
      <span
        className={`flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium ${iconClasses}`}
      >
        {item.initial}
      </span>
      <span className="truncate">{item.name}</span>
    </button>
  );
};

export default AsideButton;