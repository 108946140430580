import React from 'react';

const MainContentButton = ({ item, onClick }) => {
  const buttonClasses = item.current
    ? 'bg-gradient-primary text-black'
    : 'text-secondary hover:text-secondary-hover hover:bg-gray-800';

  const iconClasses = item.current
    ? 'text-black'
    : 'text-secondary group-hover:text-secondary-hover';

  return (
    <button
      type="button"
      onClick={() => onClick(item)}
      className={`group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 ${buttonClasses}`}
    >
      <item.icon
        aria-hidden="true"
        className={`h-6 w-6 shrink-0 ${iconClasses}`}
      />
      {item.visibleName}
    </button>
  );
};

export default MainContentButton;
