// src/pages/general/solutions/MatrixGen/shared components/AnimatedListItem.js
import { motion } from 'framer-motion';
import { listItemVariants } from './variants'; // Adjust the path as needed

export default function AnimatedListItem({ icon: Icon, title, description, margin }) {
  // Convert the provided margin to percentage format
  const marginValue = `${margin}% 0px ${margin}% 0px`;

  return (
    <motion.li
      className="flex gap-x-3"
      variants={listItemVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: marginValue }} // Use the dynamic margin here
    >
      <Icon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-accent" />
      <span className="text-secondary">
        <strong className="font-semibold text-custom-white">{title}: </strong>
        {description}
      </span>
    </motion.li>
  );
}

