// singleQueriesHistorySlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchSingleQueryHistory, fetchSingleMostRecentQuery } from "../../../../../../services/singleQueryHistoryService";
import { isToday, subDays, isWithinInterval } from 'date-fns';

const initialState = {
  queriesToday: [],
  queriesLast7Days: [],
  queriesLast30Days: [],
  olderBuckets: {},
  queryDetail: null,
  loading: false,       // New flag to indicate loading state
  error: null,          // To capture any errors
  hasMore: true,        // Flag to indicate if more data is available
};

const singleQueriesHistorySlice = createSlice({
  name: 'singleQueriesHistorySlice',
  initialState,

  reducers: {
    // Existing reducers...
    setQueriesToday: (state, action) => {
      state.queriesToday = action.payload;
    },
    appendToQueriesToday: (state, action) => {
      state.queriesToday = [...state.queriesToday, ...action.payload];
    },
    setQueriesLast7Days: (state, action) => {
      state.queriesLast7Days = action.payload;
    },
    appendToQueriesLast7Days: (state, action) => {
      state.queriesLast7Days = [...state.queriesLast7Days, ...action.payload];
    },
    setQueriesLast30Days: (state, action) => {
      state.queriesLast30Days = action.payload;
    },
    appendToQueriesLast30Days: (state, action) => {
      state.queriesLast30Days = [...state.queriesLast30Days, ...action.payload];
    },
    setOlderBuckets: (state, action) => {
      state.olderBuckets = action.payload;
    },
    updateOlderBuckets: (state, action) => {
      const { bucketKey, queries } = action.payload;
      if (!state.olderBuckets[bucketKey]) {
        state.olderBuckets[bucketKey] = [];
      }
      state.olderBuckets[bucketKey] = [...state.olderBuckets[bucketKey], ...queries];
    },
    prependMostRecentQuery: (state, action) => {
      state.queriesToday = [action.payload, ...state.queriesToday];
    },
    setQueryDetail: (state, action) => {
      state.queryDetail = action.payload;
    },
    // New reducers for loading and error states
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
  },
});

export const {
  setQueriesToday,
  appendToQueriesToday,
  setQueriesLast7Days,
  appendToQueriesLast7Days,
  setQueriesLast30Days,
  appendToQueriesLast30Days,
  setOlderBuckets,
  updateOlderBuckets,
  prependMostRecentQuery,
  setQueryDetail,
  setLoading,
  setError,
  setHasMore,
} = singleQueriesHistorySlice.actions;

// Thunk to fetch and set query list (replaces the list when offset is 0)
export const fetchAndSetQueryHistory = (offset = 0, limit = 5) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    const data = await fetchSingleQueryHistory(offset, limit);

    if (offset === 0) {
      dispatch(setQueriesToday([]));
      dispatch(setQueriesLast7Days([]));
      dispatch(setQueriesLast30Days([]));
      dispatch(setOlderBuckets({}));
    }

    // Update appropriate buckets based on the fetched queries
    const today = new Date();
    const queriesToday = [];
    const queriesLast7Days = [];
    const queriesLast30Days = [];
    const olderBuckets = {};

    data.forEach((query) => {
      const createdAtDate = new Date(query.created_at);
      const bucketKey = `${createdAtDate.getFullYear()}-${String(createdAtDate.getMonth() + 1).padStart(2, '0')}`;

      if (isToday(createdAtDate)) {
        queriesToday.push(query);
      } else if (isWithinInterval(createdAtDate, { start: subDays(today, 7), end: today })) {
        queriesLast7Days.push(query);
      } else if (isWithinInterval(createdAtDate, { start: subDays(today, 30), end: today })) {
        queriesLast30Days.push(query);
      } else {
        if (!olderBuckets[bucketKey]) {
          olderBuckets[bucketKey] = [];
        }
        olderBuckets[bucketKey].push(query);
      }
    });

    dispatch(setQueriesToday(queriesToday));
    dispatch(setQueriesLast7Days(queriesLast7Days));
    dispatch(setQueriesLast30Days(queriesLast30Days));
    dispatch(setOlderBuckets(olderBuckets));

    // Determine if more data is available
    if (data.length < limit) {
      dispatch(setHasMore(false));
    } else {
      dispatch(setHasMore(true));
    }
  } catch (error) {
    console.error('Error fetching and setting query history:', error);
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

// Thunk to append paginated queries (offset greater than 0)
export const fetchAndAppendQueryHistory = (offset, limit = 5) => async (dispatch, getState) => {
  const { hasMore, loading } = getState().singleQueriesHistorySlice;

  if (!hasMore || loading) return; // Prevent fetch if no more data or already loading

  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    const data = await fetchSingleQueryHistory(offset, limit);

    const today = new Date();
    const todayQueries = [];
    const last7DaysQueries = [];
    const last30DaysQueries = [];
    const updatedOlderBuckets = {};

    data.forEach((query) => {
      const createdAtDate = new Date(query.created_at);
      const bucketKey = `${createdAtDate.getFullYear()}-${String(createdAtDate.getMonth() + 1).padStart(2, '0')}`;

      if (isToday(createdAtDate)) {
        todayQueries.push(query);
      } else if (isWithinInterval(createdAtDate, { start: subDays(today, 7), end: today })) {
        last7DaysQueries.push(query);
      } else if (isWithinInterval(createdAtDate, { start: subDays(today, 30), end: today })) {
        last30DaysQueries.push(query);
      } else {
        if (!updatedOlderBuckets[bucketKey]) {
          updatedOlderBuckets[bucketKey] = [];
        }
        updatedOlderBuckets[bucketKey].push(query);
      }
    });

    dispatch(appendToQueriesToday(todayQueries));
    dispatch(appendToQueriesLast7Days(last7DaysQueries));
    dispatch(appendToQueriesLast30Days(last30DaysQueries));

    // Update each older bucket individually to avoid full re-renders
    Object.keys(updatedOlderBuckets).forEach((key) => {
      dispatch(updateOlderBuckets({ bucketKey: key, queries: updatedOlderBuckets[key] }));
    });

    // Determine if more data is available
    if (data.length < limit) {
      dispatch(setHasMore(false));
    }
  } catch (error) {
    console.error('Error fetching and appending query history:', error);
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

// Thunk to fetch and prepend the most recent query
export const fetchAndPrependMostRecentQuery = () => async (dispatch) => {
  try {
    const data = await fetchSingleMostRecentQuery();

    // Convert `created_at` to a Date object
    if (data.created_at) {
      data.created_at = new Date(data.created_at);
    } else {
      console.error('No created_at date found in the most recent query:', data);
      return;
    }

    dispatch(prependMostRecentQuery(data));
  } catch (error) {
    console.error('Error fetching and prepending the most recent query:', error);
  }
};


export default singleQueriesHistorySlice.reducer;
