import React from 'react';

import Hero from "./components/Hero";
import Bento from "./components/Bento"
import Features from "./components/Features";
import CTA from "./components/CTA";

export default function Home() {
  return (
    <>
        <Hero />
        <Features />
        <Bento />
        <CTA />
    </>
  );
};
