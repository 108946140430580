// src/features/user/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosInstance from '../../components/AxiosInstance';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const response = await AxiosInstance.get('dashboard_backend/user_info/');
  const { user, teams, organization } = response.data;

  // Set user's initials
  if (user.first_name && user.last_name) {
    user.initials = user.first_name.charAt(0).toUpperCase() + user.last_name.charAt(0).toUpperCase();
  }

  return { user, teams, organization };
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user_data: {
      user: null,
      teams: [],
      organization: null
      },
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user_data = action.payload;  // Corrected key from `user` to `user_data`
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default userSlice.reducer;
