// Features.js
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { useInViewAnimation } from '../../../../hooks/useInViewAnimation';
import {featureVariants, imageVariants, textVariants} from './variants';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, WrenchIcon, PuzzlePieceIcon, CodeBracketIcon, LifebuoyIcon } from '@heroicons/react/20/solid';
import AppScreenshot from '../../../../assets/AppScreenshot.png';
import {AcademicCapIcon, BanknotesIcon, BriefcaseIcon, ForwardIcon} from "@heroicons/react/24/outline";

const features = [
  {
    name: 'User-Friendly Interface',
    description: 'A simple, intuitive interface that allows clients to use AI models without any coding knowledge.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'No-Code Model Setup',
    description: 'Easily set up and manage AI models through our interface, with no coding skills required.',
    icon: WrenchIcon,
  },
  {
    name: 'Seamless Workflow Integration',
    description: 'Integrate AI capabilities directly into your existing processes effortlessly, without technical barriers.',
    icon: PuzzlePieceIcon,
  },
  {
    name: 'Guided AI Configuration',
    description: 'Step-by-step guidance for configuring models, making complex AI tasks as simple as a few clicks.',
    icon: CodeBracketIcon,
  },
  {
    name: 'Cost Savings',
    description: 'Eliminate the need to hire an in-house team for model creation, significantly reducing operational costs.',
    icon: BanknotesIcon,
  },
  {
    name: 'Comprehensive Support',
    description: 'Our team is available to assist with any questions, ensuring you get the most out of our no-code AI solution.',
    icon: LifebuoyIcon,
  },
];

export default function Features() {
  const headerRef = useRef();
  const imageRef = useRef();
  const featuresRef = useRef();

  const controls = useInViewAnimation(headerRef, 0.5);
  const featureControls = useInViewAnimation(featuresRef, 0, '0px 0px 25% 0px');

  return (
    <div className="relative isolate py-16 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <motion.h1
            ref={headerRef}
            className="text-base font-semibold leading-7 text-gradient-primary"
            initial="hidden"
            animate={controls}
            variants={textVariants}
            custom={0}
          >
            Platform
          </motion.h1>
          <motion.h2
            ref={headerRef}
            className="mt-2 text-3xl font-semibold tracking-tight text-white sm:text-5xl"
            initial="hidden"
            animate={controls}
            variants={textVariants}
            custom={0}
          >
            A friendly interface to interact with your AI solutions.
          </motion.h2>
        </div>
      </div>

      <motion.div
        variants={imageVariants}
        initial="hidden"
        animate={controls}
        className="hidden sm:block relative overflow-hidden pt-16 mx-auto max-w-7xl px-6 lg:px-8"
      >
        {/* Image Section */}
        <img
          alt="App screenshot"
          src={AppScreenshot}
          width={1920}
          height={1080}
          className="mb-[-10%] rounded-xl shadow-2xl ring-1 ring-white/10"
        />
        <div aria-hidden="true" className="relative">
          <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-950 via-gray-950/80 to-transparent pt-[20%]"/>
        </div>
      </motion.div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature, index) => (
            <motion.div
              ref={featuresRef}
              key={feature.name}
              variants={featureVariants}
              initial="hidden"
              animate={featureControls}
              custom={index}
              className="relative pl-9"
            >
              <dt className="inline font-semibold text-white">
                <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-accent" />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </motion.div>
          ))}
        </dl>
      </div>
    </div>
  )
}
