// CTA.js
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { textVariants } from './variants'; // Import shared text variants
import { useInViewAnimation } from '../../../../hooks/useInViewAnimation'; // Import custom hook
import Button from '../../../../components/Button';

export default function CTA() {
  const ref = useRef();
  const controls = useInViewAnimation(ref);

  return (
    <div ref={ref} className="relative isolate px-6 py-32 sm:py-40 lg:px-8">
      {/* SVG backgrounds (unchanged) */}
      <svg
        aria-hidden="true"
        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      >
        {/* ... SVG content ... */}
      </svg>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
      >
        <div
          style={{
            clipPath:
              'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
          }}
          className="aspect-[1108/632] w-[69.25rem] flex-none bg-accent opacity-20"
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <motion.h2
          className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
          variants={textVariants}
          initial="hidden"
          animate={controls}
          custom={0}
        >
          Boost your productivity.
          <br />
          Start using our app today.
        </motion.h2>
        <motion.p
          className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300"
          variants={textVariants}
          initial="hidden"
          animate={controls}
          custom={1}
        >
          Book a consultation today to discover how we can help integrate models within your organization's workflow.
        </motion.p>
        <motion.div
          className="mt-10 flex items-center justify-center gap-x-6"
          variants={textVariants}
          initial="hidden"
          animate={controls}
          custom={2}
        >
          <Button href="mailto:irsik.solutions@gmail.com?subject=Consulting%20Request">
            Contact Us
          </Button>
          <a href="/MatrixGen" className="text-sm font-semibold leading-6 text-white">
            Learn more <span aria-hidden="true">→</span>
          </a>
        </motion.div>
      </div>
    </div>
  );
}
