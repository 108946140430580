import React, { useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from "@headlessui/react";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";
import ilogo from '../../assets/i-logo.png';

import { LeftLogoutButton } from '../../components/LogoutButton'
import Solutions from './components/solutions/Solutions';
import Teams from './components/teams/Teams';
import User from './components/User';
import {ArrowLongLeftIcon, ArrowLongRightIcon} from "@heroicons/react/24/solid";

export default function SideBar({ handleTabChange }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 xl:hidden">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div
                className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                </button>
              </div>
            </TransitionChild>
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10">
              <div className="flex h-16 shrink-0 items-center">
                <img alt="Irsiks" src={ilogo} className="h-8 w-auto" />
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  <li>
                    <div className="text-xs font-semibold leading-6 text-secondary">Your solutions</div>
                    <Solutions setSidebarOpen={setSidebarOpen} />
                  </li>
                  <li>
                    <div className="text-xs font-semibold leading-6 text-secondary">Your teams</div>
                    <Teams setSidebarOpen={setSidebarOpen} />
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col bg-gray-950">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 ring-1 ring-white/5">
          <div className="flex h-16 shrink-0 items-center">
            <img alt="Irsiks" src={ilogo} className="h-8 w-auto"/>

            <LeftLogoutButton extra_class="ml-auto"/>

          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <div className="text-xs font-semibold leading-6 text-secondary">Your solutions</div>
                <Solutions setSidebarOpen={setSidebarOpen}/>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-secondary">Your teams</div>
                <Teams setSidebarOpen={setSidebarOpen} />
              </li>
              <li className="-mx-6 mt-auto">
                <User />
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon aria-hidden="true" className="h-6 w-6" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">Dashboard</div>
        <User />
      </div>
    </>
  );
}
