import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSolution, setCurrentTab } from '../../../utils/redux/uiSlice';
import { RightLogoutButton } from '../../../components/LogoutButton';

function User() {
  const dispatch = useDispatch();
  const { user_data } = useSelector((state) => state.userInfo);

  // Extracting teams from user_data
  const user = user_data ? user_data.user : [];

  // Extract initials if user data exists
  const userInitials = user?.initials ? user.initials : '';
  const userName = user ? `${user.first_name} ${user.last_name}` : 'Loading...';

  return (
    <>
      <a
        href="#"
        className="flex items-center gap-x-4 lg:px-6 lg:py-3 sm:px-2 sm:py-1 text-sm font-semibold leading-6 text-secondary hover:bg-gray-800"
        onClick={(e) => {
          e.preventDefault();
          // Uncomment these lines to use when needed
          // dispatch(setCurrentSolution('Settings'));
          // dispatch(setCurrentTab('Docs'));
        }}
      >
        <span
          className={`bg-primary-two h-8 w-8 flex items-center justify-center rounded-lg border text-lg font-semibold border-black text-black`}
        >
          {userInitials}
        </span>

        <span className="sr-only">Your profile</span>
        <span className="hidden sm:inline text-secondary group-hover:text-secondary-hover" aria-hidden="true">
          {userName}
        </span>
      </a>

      <RightLogoutButton extra_class="lg:hidden" />
    </>
  );
}

export default User;
