// ModelMenu.js
import { useDispatch, useSelector } from "react-redux";
import { setCurrentModel } from '../../../../utils/redux/uiSlice';
import { useModelRetrievalService } from "../../../../services/modelRetrievalService";

import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export default function ModelMenu({ disabled }) {
  const dispatch = useDispatch();
  const { currentModel } = useSelector((state) => state.ui);
  const modelsList = useModelRetrievalService();

  const handleModelChange = (model) => {
    dispatch(setCurrentModel(model));
  };

  // Optional: Remove in production
  console.log('Current Model: ', currentModel);

  // Optional: Handle loading and error states
  if (!modelsList) {
    return <div>Loading models...</div>;
  }

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <div className="relative inline-block group">
              {/* Blur Background */}
              <div
                className={`absolute -inset-1 rounded-2xl z-0 blur-sm ${
                  disabled
                    ? 'opacity-50 bg-red-500'
                    : 'opacity-75 group-hover:opacity-100 bg-gradient-to-r from-primary-one to-primary-two'
                }`}
              ></div>


              {/* Button */}
              <Popover.Button
                className={`relative z-10 inline-flex items-center gap-x-1 outline-0 text-sm font-semibold leading-6 text-secondary rounded-2xl p-base px-md bg-gray-950 ${
                  disabled ? 'cursor-not-allowed' : ''}`}
                disabled={disabled}
              >
                <span>{currentModel?.name || 'Select Model'}</span> {/* Handle undefined name */}
                <ChevronDownIcon aria-hidden="true" className="h-5 w-5"/>
              </Popover.Button>
            </div>

            <Popover.Panel
              className="absolute left-1/2 z-10 mt-base flex w-screen max-w-max -translate-x-1/2 px-4 transition-transform duration-200 ease-out"
            >
              <div
                className="w-screen max-w-sm flex-auto rounded-2xl bg-gray-950 p-md text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
              >
                <h1 className="text-sm text-tertiary mb-md px-base">Model</h1>
                {modelsList.map((model) => (
                  <button
                    key={model.id}
                    onClick={() => {
                      handleModelChange(model);
                      close(); // Close the popover
                    }}
                    className="relative w-full px-base py-md rounded-lg hover:bg-gray-900 text-left focus:outline-none"
                  >
                    <div className="font-semibold text-secondary">
                      {model.name}
                    </div>
                    <p className="text-tertiary">{model.description}</p>
                  </button>
                ))}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
      {/*{disabled && (*/}
      {/*  <p className="mt-md text-sm font-medium text-red-500">*/}
      {/*    Model selection is disabled when "Format Only" is enabled.*/}
      {/*  </p>*/}
      {/*)}*/}
    </>
  );
}
