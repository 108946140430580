// Pricing.js
import { useState } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { GridPricing } from '../../../components/Grid';

const includedFeatures = [
  'Unlimited Single Requirement Classifications',
  'Post Query Support',
  'PDF to Excel Conversion',
  'Simple Excel Sheet Classification',
];

export default function Pricing() {
  const [pages, setPages] = useState('1');
  const [costInCAD, setCostInCAD] = useState(25); // Base price in CAD
  const [cost, setCost] = useState(costInCAD);
  const [currency, setCurrency] = useState('CAD');

  const conversionRate = 1.35; // Assuming 1 USD = 1.35 CAD

  const handleInputChange = (event) => {
    const value = event.target.value;
    setPages(value);

    const pagesCount = parseInt(value, 10);
    if (!isNaN(pagesCount)) {
      const newCostInCAD = pagesCount * 25;
      setCostInCAD(newCostInCAD);
      if (currency === 'CAD') {
        setCost(newCostInCAD);
      } else {
        setCost(Math.round(newCostInCAD / conversionRate));
      }
    } else {
      setCostInCAD(0);
      setCost(0);
    }
  };

  const toggleCurrency = () => {
    if (currency === 'USD') {
      setCurrency('CAD');
      setCost(costInCAD);
    } else {
      setCurrency('USD');
      setCost(Math.round(costInCAD / conversionRate));
    }
  };

  return (
    <div>
      <GridPricing />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center mb-24 sm:mb-32">
          <h2 className="text-4xl font-bold tracking-tight text-custom-white sm:text-6xl">
            Simple No-Tricks <span className="text-gradient-primary">Pricing</span>
          </h2>
        </div>
        <div
          className="mx-auto max-w-2xl rounded-3xl ring-1 ring-primary-one lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex items-center">
              <h3 className="text-3xl font-bold tracking-tight text-custom-white">
                Matrix<span className="text-gradient-primary">Gen™</span>
              </h3>
            </div>
            <p className="mt-6 text-base leading-7 text-secondary">
              This product processes PDF documents by extracting individual requirements, classifying them, and
              presenting the results in a structured, tabular format.
              <a href="/MatrixGen" className="ml-2 text-sm font-semibold leading-6 text-accent hover:text-accent-hover justify-center">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-primary-one">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100"/>
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-secondary sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-accent"/>
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div
              className="rounded-2xl bg-gray-800 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-custom-white">PDF to RVTM Conversion Estimate: </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-accent">${cost.toLocaleString()}</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-accent-hover">{currency}</span>
                </p>
                <div className="mt-10 flex rounded-md shadow-sm group focus-within:border-gray-seperator-main">
                  <span
                    className="inline-flex items-center rounded-l-md border border-r-0 border-gray-seperator-secondary px-3 text-tertiary sm:text-sm bg-gray-900 group-focus-within:border-gray-seperator-main">
                    Pages
                  </span>
                  <input
                    id="company-website"
                    name="company-website"
                    type="text"
                    placeholder="123"
                    value={pages}
                    onChange={handleInputChange}
                    className="bg-gray-900 block w-full min-w-0 flex-1 rounded-none rounded-r-md border border-gray-seperator-secondary py-1.5 text-custom-white font-medium placeholder:text-tertiary focus:border-gray-seperator-main focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>

                <p className="mt-2 text-xs leading-5 text-secondary">
                  *Input the estimated number of pages to be transformed into our RVTM format
                </p>
                <button
                  onClick={toggleCurrency}
                  className="mt-5 block w-full rounded-md bg-accent px-3 py-2 text-center text-sm font-semibold text-black shadow-sm hover:bg-accent-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Convert to {currency === 'USD' ? 'CAD' : 'USD'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
