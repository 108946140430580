import Button from "./Button";

export function ErrorPageGeneral() {
  return (
    <div className="flex flex-col bg-gray-950">
      <header className="flex-shrink-0">
        {/* Your header content goes here */}
      </header>

      <main className="flex-grow flex flex-col justify-center items-center text-center">
        <p className="text-3xl font-semibold text-gradient-primary">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-custom-white sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-secondary">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button href="/">
              Go back home
          </Button>
          <a href="mailto:irsik.solutions@gmail.com" className="text-sm font-semibold text-custom-white">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </main>

      <footer className="flex-shrink-0">
        {/* Your footer content goes here */}
      </footer>
    </div>
  );
}


export function ErrorPageApplication() {
  return (
    <div className="flex flex-col justify-center items-center text-center bg-gray-950 min-h-screen">
      <p className="text-3xl font-semibold text-gradient-primary">404</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-custom-white sm:text-5xl">Page not found</h1>
      <p className="mt-6 text-base leading-7 text-secondary">Sorry, we couldn’t find the page you’re looking for.</p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Button href="/">
          Go back home
        </Button>
        <a href="mailto:irsik.solutions@gmail.com" className="text-sm font-semibold text-custom-white">
          Contact support <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>

  )
}

