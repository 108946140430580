// Hero.js
import { motion } from 'framer-motion';
import Button from '../../../../components/Button';
import { BottomRightSectionPolygon } from '../../../../components/BackgroundPolygons';
import { GridMainHero } from '../../../../components/Grid';
import AppScreenshot from '../../../../assets/AppScreenshot.png';

import { textVariants } from './variants'; // Adjusted import path

export default function Hero() {
  return (
    <div className="relative isolate py-16 sm:py-32">
      <GridMainHero />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-0 sm:mt-16">
        <div className="mx-auto max-w-2xl text-center pb-16 sm:pb-32">
          <motion.h1
            className="text-4xl font-bold tracking-tight text-custom-white sm:text-6xl"
            initial="hidden"
            animate="visible"
            variants={textVariants}
            custom={0}
          >
            <span className="text-gradient-primary">AI</span> Solutions: Redefining Elegance in Simplicity
          </motion.h1>
          <motion.p
            className="mt-6 text-lg leading-8 text-secondary"
            initial="hidden"
            animate="visible"
            variants={textVariants}
            custom={1}
          >
            We specialize in leveraging cutting-edge AI technology to revolutionize the construction industry. Our mission is to enhance efficiency with elegant, simple solutions during the procurement and design phases of your projects.
          </motion.p>
          <motion.div
            className="mt-10 flex items-center justify-center gap-x-6"
            initial="hidden"
            animate="visible"
            variants={textVariants}
            custom={2}
          >
            <Button href="/login">Get started</Button>
            <a href="/MatrixGen" className="text-sm font-semibold leading-6 text-custom-white">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </motion.div>
        </div>
      </div>
      <BottomRightSectionPolygon />
    </div>
  );
}
