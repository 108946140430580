// multiFileQueriesHistorySlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchMultiQueryHistory, fetchMultiMostRecentQuery, fetchMultiNonCompletedQueries } from "../../../../../../services/multiFileQueryHistoryRetrievalService";
import { isToday, subDays, isWithinInterval } from 'date-fns';

const initialState = {
  queriesToday: [],
  queriesLast7Days: [],
  queriesLast30Days: [],
  olderBuckets: {},
  queryDetail: null,
  loading: false,       // New flag to indicate loading state
  error: null,          // To capture any errors
  hasMore: true,        // Flag to indicate if more data is available
};

const multiFileQueriesHistorySlice = createSlice({
  name: 'multiFileQueriesHistorySlice',
  initialState,

  reducers: {
    // Existing reducers...
    setQueriesToday: (state, action) => {
      state.queriesToday = action.payload;
    },
    appendToQueriesToday: (state, action) => {
      state.queriesToday = [...state.queriesToday, ...action.payload];
    },
    setQueriesLast7Days: (state, action) => {
      state.queriesLast7Days = action.payload;
    },
    appendToQueriesLast7Days: (state, action) => {
      state.queriesLast7Days = [...state.queriesLast7Days, ...action.payload];
    },
    setQueriesLast30Days: (state, action) => {
      state.queriesLast30Days = action.payload;
    },
    appendToQueriesLast30Days: (state, action) => {
      state.queriesLast30Days = [...state.queriesLast30Days, ...action.payload];
    },
    setOlderBuckets: (state, action) => {
      state.olderBuckets = action.payload;
    },
    updateOlderBuckets: (state, action) => {
      const { bucketKey, queries } = action.payload;
      if (!state.olderBuckets[bucketKey]) {
        state.olderBuckets[bucketKey] = [];
      }
      state.olderBuckets[bucketKey] = [...state.olderBuckets[bucketKey], ...queries];
    },
    prependMostRecentQuery: (state, action) => {
      state.queriesToday = [action.payload, ...state.queriesToday];
    },
    setQueryDetail: (state, action) => {
      state.queryDetail = action.payload;
    },
    // New reducers for loading and error states
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
  },
});

export const {
  setQueriesToday,
  appendToQueriesToday,
  setQueriesLast7Days,
  appendToQueriesLast7Days,
  setQueriesLast30Days,
  appendToQueriesLast30Days,
  setOlderBuckets,
  updateOlderBuckets,
  prependMostRecentQuery,
  setQueryDetail,
  setLoading,
  setError,
  setHasMore,
} = multiFileQueriesHistorySlice.actions;

// Thunk to fetch and set query list (replaces the list when offset is 0)
export const fetchAndSetQueryHistory = (offset = 0, limit = 5) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    const data = await fetchMultiQueryHistory(offset, limit);

    if (offset === 0) {
      dispatch(setQueriesToday([]));
      dispatch(setQueriesLast7Days([]));
      dispatch(setQueriesLast30Days([]));
      dispatch(setOlderBuckets({}));
    }

    // Update appropriate buckets based on the fetched queries
    const today = new Date();
    const queriesToday = [];
    const queriesLast7Days = [];
    const queriesLast30Days = [];
    const olderBuckets = {};

    data.forEach((query) => {
      const createdAtDate = new Date(query.created_at);
      const bucketKey = `${createdAtDate.getFullYear()}-${String(createdAtDate.getMonth() + 1).padStart(2, '0')}`;

      if (isToday(createdAtDate)) {
        queriesToday.push(query);
      } else if (isWithinInterval(createdAtDate, { start: subDays(today, 7), end: today })) {
        queriesLast7Days.push(query);
      } else if (isWithinInterval(createdAtDate, { start: subDays(today, 30), end: today })) {
        queriesLast30Days.push(query);
      } else {
        if (!olderBuckets[bucketKey]) {
          olderBuckets[bucketKey] = [];
        }
        olderBuckets[bucketKey].push(query);
      }
    });

    dispatch(setQueriesToday(queriesToday));
    dispatch(setQueriesLast7Days(queriesLast7Days));
    dispatch(setQueriesLast30Days(queriesLast30Days));
    dispatch(setOlderBuckets(olderBuckets));

    // Determine if more data is available
    if (data.length < limit) {
      dispatch(setHasMore(false));
    } else {
      dispatch(setHasMore(true));
    }
  } catch (error) {
    console.error('Error fetching and setting query history:', error);
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

// Thunk to append paginated queries (offset greater than 0)
export const fetchAndAppendQueryHistory = (offset, limit = 5) => async (dispatch, getState) => {
  const { hasMore, loading } = getState().multiFileQueriesHistorySlice;

  if (!hasMore || loading) return; // Prevent fetch if no more data or already loading

  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    const data = await fetchMultiQueryHistory(offset, limit);

    const today = new Date();
    const todayQueries = [];
    const last7DaysQueries = [];
    const last30DaysQueries = [];
    const updatedOlderBuckets = {};

    data.forEach((query) => {
      const createdAtDate = new Date(query.created_at);
      const bucketKey = `${createdAtDate.getFullYear()}-${String(createdAtDate.getMonth() + 1).padStart(2, '0')}`;

      if (isToday(createdAtDate)) {
        todayQueries.push(query);
      } else if (isWithinInterval(createdAtDate, { start: subDays(today, 7), end: today })) {
        last7DaysQueries.push(query);
      } else if (isWithinInterval(createdAtDate, { start: subDays(today, 30), end: today })) {
        last30DaysQueries.push(query);
      } else {
        if (!updatedOlderBuckets[bucketKey]) {
          updatedOlderBuckets[bucketKey] = [];
        }
        updatedOlderBuckets[bucketKey].push(query);
      }
    });

    dispatch(appendToQueriesToday(todayQueries));
    dispatch(appendToQueriesLast7Days(last7DaysQueries));
    dispatch(appendToQueriesLast30Days(last30DaysQueries));

    // Update each older bucket individually to avoid full re-renders
    Object.keys(updatedOlderBuckets).forEach((key) => {
      dispatch(updateOlderBuckets({ bucketKey: key, queries: updatedOlderBuckets[key] }));
    });

    // Determine if more data is available
    if (data.length < limit) {
      dispatch(setHasMore(false));
    }
  } catch (error) {
    console.error('Error fetching and appending query history:', error);
    dispatch(setError(error.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

// Thunk to fetch and prepend the most recent query
export const fetchAndPrependMostRecentQuery = () => async (dispatch) => {
  try {
    const data = await fetchMultiMostRecentQuery();

    // Convert `created_at` to a Date object
    if (data.created_at) {
      data.created_at = new Date(data.created_at);
    } else {
      console.error('No created_at date found in the most recent query:', data);
      return;
    }

    dispatch(prependMostRecentQuery(data));
  } catch (error) {
    console.error('Error fetching and prepending the most recent query:', error);
  }
};

// Thunk to fetch and update non-completed queries
export const fetchAndUpdateNonCompletedQueries = () => async (dispatch, getState) => {
  try {
    // Fetch non-completed queries from the backend
    const nonCompletedQueries = await fetchMultiNonCompletedQueries();

    // Create a map for quick lookup of non-completed queries by their ID
    const nonCompletedMap = new Map(
      nonCompletedQueries.map(query => [query.additional_details.id, query])
    );

    // Extract existing queries from the state
    const { queriesToday, queriesLast7Days, queriesLast30Days, olderBuckets } = getState().multiFileQueriesHistorySlice;

    // Helper function to update queries within a specific bucket
    const updateQueries = (existingQueries) => {
      return existingQueries.map(existingQuery => {
        const queryId = existingQuery.additional_details.id;
        const updatedQuery = nonCompletedMap.get(queryId);

        if (updatedQuery) {
          // Query is still non-completed; check if status or completed counts have changed
          if (
            updatedQuery.additional_details.status !== existingQuery.additional_details.status ||
            updatedQuery.additional_details.request_counts.completed !== existingQuery.additional_details.request_counts.completed
          ) {
            return updatedQuery; // Return the updated version
          }
          return existingQuery; // No changes needed
        } else {
          // Query is not in the non-completed list; mark it as completed
          return {
            ...existingQuery,
            additional_details: {
              ...existingQuery.additional_details,
              status: "completed",
            }
          };
        }
      });
    };

    // Update "Today" bucket
    const updatedQueriesToday = updateQueries(queriesToday);
    dispatch(setQueriesToday(updatedQueriesToday));

    // Update "Last 7 Days" bucket
    const updatedQueriesLast7Days = updateQueries(queriesLast7Days);
    dispatch(setQueriesLast7Days(updatedQueriesLast7Days));

    // Update "Last 30 Days" bucket
    const updatedQueriesLast30Days = updateQueries(queriesLast30Days);
    dispatch(setQueriesLast30Days(updatedQueriesLast30Days));

    // Update "Older" buckets
    const updatedOlderBuckets = { ...olderBuckets };
    Object.keys(updatedOlderBuckets).forEach(bucketKey => {
      updatedOlderBuckets[bucketKey] = updateQueries(updatedOlderBuckets[bucketKey]);
    });
    dispatch(setOlderBuckets(updatedOlderBuckets));

  } catch (error) {
    console.error('Error fetching and updating non-completed queries:', error);
    // Optionally, dispatch an error action to update the state
    dispatch(setError(error.toString()));
  }
};

export default multiFileQueriesHistorySlice.reducer;
