import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ilogo from "../../assets/i-logo.png";
import AxiosInstance from '../../components/AxiosInstance';
import { TopSectionPolygon, BottomRightSectionPolygon } from '../../components/BackgroundPolygons';
import { SuccessAlert, ErrorAlert } from '../../components/Alerts';
import TextField from '../../components/textfields/TextField';

export default function PasswordResetRequest() {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await AxiosInstance.post('api/password_reset/', {
        email: data.email,
      }).then((respones) => {
        setShowMessage(true);
      })
    } catch (error) {
      console.log('Error response:', error.response);
      if (error.response && error.response.status === 400) {
        const errorData = error.response.data;
        console.log('Error response data:', errorData);

        if (errorData.email) {
          setError('email', {
            type: 'manual',
            message: errorData.email[0],
          });
        }

        // Handle general errors (like group limit)
        if (errorData.non_field_errors || errorData.detail) {
          setError('root', {
            type: 'manual',
            message: errorData.non_field_errors ? errorData.non_field_errors[0] : errorData.detail,
          });
        }

      } else {
        setError('root', {
          type: 'manual',
          message: 'An unexpected error occurred. Please try again later!',
        });
        console.error('An unexpected error occurred:', error);
      }
    }
  };

  return (
    <>
      <div className="relative isolate overflow-clip">
        <TopSectionPolygon />
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <a href="/">
              <img
                alt="Your Company"
                src={ilogo}
                className="mx-auto h-10 w-auto"
              />
            </a>
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
              Reset password request
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" noValidate>
              {showMessage && (
                <SuccessAlert message="Check your email for password reset instructions." />
              )}
              {errors.root && (
                <ErrorAlert message={errors.root.message} />
              )}
              <TextField
                id="email"
                label="Email address"
                type="email"
                placeholder="Enter your email"
                register={register}
                errors={errors}
                validationRules={{
                  required: 'Email is required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email address is invalid',
                  },
                }}
                getValues={getValues}
              />
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gradient-primary px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-gradient-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                >
                  Reset Password Request
                </button>
              </div>
            </form>
          </div>
        </div>
        <BottomRightSectionPolygon />
      </div>
    </>
  );
}
