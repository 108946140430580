// Teams component
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTeam } from '../../../../utils/redux/uiSlice';
import AsideButton from "./AsideButton";

export default function Teams({ setSidebarOpen }) {
  const dispatch = useDispatch();
  const { currentTeam } = useSelector((state) => state.ui);
  const teams = useSelector((state) => state.userInfo.user_data?.teams ?? []);

  const [teamsList, setTeamsList] = useState([]);

  useEffect(() => {
    if (teams && teams.length > 0) {
      // Create a formatted teams list from Redux state
      const teamsArray = teams.map((team) => ({
        id: team.id,
        name: team.name,
        initial: team.name.charAt(0).toUpperCase(),
        current: team.name === 'General',
      }));

      const sortedTeams = teamsArray.sort((a, b) => {
        if (a.name === 'General') return -1;
        if (b.name === 'General') return 1;
        return a.name.localeCompare(b.name);
      });

      setTeamsList(sortedTeams);
    }
  }, [teams]);

  const handleTeamsChange = (item) => {
    dispatch(setCurrentTeam({ ...item, current: true }));
    setTeamsList(
      teamsList.map((team) =>
        team.name === item.name ? { ...team, current: true } : { ...team, current: false }
      )
    );
    setSidebarOpen(false);
  };

  return (
    <ul role="list" className="-mx-2 mt-2 space-y-1">
      {teamsList.map((item) => (
        <li key={item.id}>
          <AsideButton item={item} onClick={() => handleTeamsChange(item)} />
        </li>
      ))}
    </ul>
  );
}
