// singleQuerySlice.js
import { createSlice } from '@reduxjs/toolkit';

export const singleQuerySlice = createSlice({
  name: 'singleQuery',
  initialState: {
    input: '',
    userMessage: '',
    botMessage: '',
  },
  reducers: {
    setInput: (state, action) => {
      state.input = action.payload;
    },
    setUserMessage: (state, action) => {
      state.userMessage = action.payload;
    },
    setBotMessage: (state, action) => {
      state.botMessage = action.payload;
    },
    clearMessages: (state) => {
      state.userMessage = '';
      state.botMessage = '';
    },
  },
});

export const { setInput, setUserMessage, setBotMessage, clearMessages } = singleQuerySlice.actions;

export default singleQuerySlice.reducer;
