import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

export default function TermsOfUse() {
	const UpdatedDate = 'October 27, 2024'

	return (
		<div className="mx-auto px-6 lg:px-8 mb-24 sm:mb-32">
			<div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
				{/* Heading */}
				<div className="text-center">
					<p className="text-base font-semibold leading-7 text-gradient-primary">Updated: {UpdatedDate}</p>
					<h1 className="mt-base text-pretty text-4xl font-semibold tracking-tight text-custom-white sm:text-7xl">
						Terms of use
					</h1>
				</div>



				{/* Introduction */}
				<div className="mt-2xl text-base text-secondary">

					{/* Effective Date */}
					<p className="mb-md text-base">
						Effective: {UpdatedDate}
					</p>
					<p className="mb-md">
						These Terms of Use apply to your use of MatrixGen™ and other services for Enterprises, along
						with any associated software applications and websites (all together, “Services”). These Terms form an
						agreement between you and Irsik Solutions., and they include our Service Terms and
						important provisions for resolving disputes through arbitration. By using our Services, you agree to these
						Terms.
					</p>
					<p>
						Our Privacy Policy explains how we collect and use personal information.
						Although it does not form part of these Terms, it is an important document that you should read.
					</p>

				</div>

				{/* Who we are */}
				<div className="mt-xl text-base text-secondary">

					{/* Heading */}
					<p className="mb-md text-2xl text-gradient-primary">
						Who We Are
					</p>
					<p className="mb-md">
						Irsik Solutions Inc. is an AI consulting and deployment firm dedicated to creating innovative solutions for
						enterprise clients and deploying them seamlessly through our platform. To learn more about Irsik Solutions,
						please visit our <a href={"/about"} className="text-accent">About Us</a> page.
					</p>
				</div>

				{/* Registration and Access */}
				<div className="mt-xl text-base text-secondary">

						{/* Heading */}
						<p className="mb-md text-2xl text-gradient-primary">
								Registration and Access
						</p>
						<p className="mb-md">
								<span className="font-medium text-custom-white">Registration: </span>
								Registration is exclusively available to companies that have signed an agreement to utilize our platform.
								A registration key will be provided to eligible companies, which is required for their users to create an account and log in.
								You must provide accurate and complete information when registering for an account to use our Services.
								Account credentials must not be shared, and your account must not be made available to anyone else.
								You are responsible for all activities that occur under your account. If you create an account or use the Services on behalf
								of another person or entity, you must have the authority to accept these Terms on their behalf.
						</p>
				</div>

				{/* Using our Services */}
				<div className="mt-xl text-base text-secondary">

						{/* Heading */}
						<p className="mb-md text-2xl text-gradient-primary">
								Using our Services
						</p>

						<p className="mb-md">
								<span className="font-medium text-custom-white">Querying: </span>
								By agreeing to these Terms of Service and proceeding with your query, you acknowledge and agree that your organization
								is financially liable for all costs associated with each query performed on our platform. This includes any fees or charges that
								may accrue as a result of utilizing our Services, regardless of the outcome of the query.
								You must ensure that all personnel authorized to perform queries are aware of the associated costs and have the appropriate
								authority to incur such expenses on behalf of your organization. Irsik Solutions reserves the right to suspend or terminate
								access to the Services if there is a failure to comply with these financial obligations. All queries are logged,
								and any disputes regarding charges must be raised within thirty (30) days of the query date.
						</p>

				</div>

				{/* Query Payment Terms */}
				<div className="mt-xl text-base text-secondary">

						{/* Heading */}
						<p className="mb-md text-2xl text-gradient-primary">
								Query Payment Terms
						</p>

						<p className="mb-md">
								<span className="font-medium text-custom-white">Payment Obligations: </span>
								Upon clicking "Proceed," you agree that your organization will be charged for this query according to the rates indicated.
								All charges are final and non-refundable, except as required by law. Payment is due upon receipt of the invoice, and late
								payments may incur additional fees as specified in our billing policy. Billing will occur according to the frequency
								agreed upon in your organization's service agreement, which may include monthly invoicing or pay-per-query options.
						</p>

				</div>

				{/* Cost Estimation and Final Payment */}
				<div className="mt-xl text-base text-secondary">

						{/* Heading */}
						<p className="mb-md text-2xl text-gradient-primary">
								Cost Estimation and Final Payment
						</p>

						<p className="mb-md">
								<span className="font-medium text-custom-white">Estimates and Final Costs: </span>
								The cost presented before initiating the query is an estimate. The final cost may vary based on factors such as the volume of data
								processed, the complexity of the query, and additional resources required to complete the request. Your organization agrees to cover
								the final amount invoiced, which may be higher than the initial estimate provided.
						</p>

				</div>

				{/* Organizational Liability */}
				<div className="mt-xl text-base text-secondary">

						{/* Heading */}
						<p className="mb-md text-2xl text-gradient-primary">
								Organizational Liability
						</p>

						<p className="mb-md">
								<span className="font-medium text-custom-white">Responsibility for Authorized Users: </span>
								Your organization is responsible for ensuring payment for all queries initiated by users authorized under its account.
								The initiation of a query signifies your organization's acceptance of this liability, and you agree to assume financial
								responsibility for any authorized or unauthorized access that occurs through your registered accounts.
						</p>

				</div>

				{/* Usage and Data Rights */}
				<div className="mt-xl text-base text-secondary">

						{/* Heading */}
						<p className="mb-md text-2xl text-gradient-primary">
								Usage and Data Rights
						</p>

						<p className="mb-md">
								<span className="font-medium text-custom-white">Data Processing and Privacy: </span>
								Any data submitted for the query will be processed in accordance with our data privacy policy. Your data may be stored temporarily
								to fulfill the requirements of the query and to improve the quality of our services. Where applicable, data will be anonymized and
								used for research and analysis purposes to enhance our platform, provided such usage complies with applicable data protection laws.
								Your organization agrees to these terms by proceeding with the query.
						</p>

				</div>

				{/* Refund Policy */}
				<div className="mt-xl text-base text-secondary">

						{/* Heading */}
						<p className="mb-md text-2xl text-gradient-primary">
								Refund Policy
						</p>

						<p className="mb-md">
								<span className="font-medium text-custom-white">No Refunds on Queries: </span>
								Queries once initiated cannot be canceled or refunded, except under special circumstances as determined at our sole discretion.
								If an error occurs on our end, we may consider adjustments or credits, but all decisions regarding refunds will be made by
								Irsik Solutions Inc. and communicated in writing.
						</p>

				</div>

				{/* Dispute Resolution */}
				<div className="mt-xl text-base text-secondary">

						{/* Heading */}
						<p className="mb-md text-2xl text-gradient-primary">
								Dispute Resolution
						</p>

						<p className="mb-md">
								<span className="font-medium text-custom-white">Payment Disputes: </span>
								If there are disputes regarding charges or services, your organization agrees to contact us within thirty (30) days of the
								invoice date. Any disputes not raised within this period are considered waived, and all charges will be deemed final. We will
								work to resolve disputes in good faith, and any unresolved matters may be subject to arbitration as outlined in our service agreement.
						</p>

				</div>

			</div>
		</div>
	)
}