import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../utils/redux/userSlice';
import { Routes, Route } from 'react-router-dom';

import Dashboard from '../pages/application/dashboard/Dashboard';
// import DashboardTest from '../pages/application/dashboard/DashboardTest';
import { ErrorPageApplication } from "../components/ErrorPage";

const ApplicationRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="" element={<Dashboard />}>
        {/* Add other nested routes here */}
      </Route>
      {/*<Route path="test" element={<DashboardTest />}>*/}
      {/*  /!* Add other nested routes here *!/*/}
      {/*</Route>*/}

      {/* Catch-all route for undefined routes */}
      <Route path="*" element={<ErrorPageApplication />} />

    </Routes>
  );
};

export default ApplicationRoutes;
